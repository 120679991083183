import { format } from 'date-fns';
import { convertCNPJ } from './form.dto';

/* eslint-disable complexity */
export interface FieldsData {
  key: string;
  type: string;
  step: string;
  flow: string[];
  readBy: string;
  question: string;
  order: number;
  label: string;
  detail: string;
  placeholder: string;
  mandatory: boolean;
  display: boolean;
  file?: any;
  permissions: {
    visible: boolean;
    editable: boolean;
  };
  options?: {
    key: string;
    label: string;
    value: string;
    conditional?: [
      {
        key: string;
        value: string;
        or?: [{ key: string; value: string }];
        and?: [{ key: string; value: string }];
      },
    ];
  }[];
  conditional: [{ key: string; value: string }];
  value: any;
  callback: string;
  id: string;
  unit?: {
    symbol: string;
    description: string;
    allowed: [
      {
        symbol: string;
        description: string;
      },
    ];
  };
  loading?: boolean;
  isConditional?: boolean;
  min?: string;
  max?: string;
  isCustom?: boolean;
  otherAttribute?: boolean;
}
interface AdditionalFieldsData {
  ADDITIONAL: FieldsData;
  DIAGONAL_DIFFERENCE: FieldsData;
  BENDING: FieldsData;
  CROOKED: FieldsData;
  LENGTH_MULTIPLES: FieldsData;
  NUMERIC_CORNER_RADIUS: FieldsData;
  REDUCTION_RATE: FieldsData;
  TOLERANCE: FieldsData;
  SIDE_TOLERANCE: FieldsData;
  TORSION: FieldsData;
  HARDENABILITY: FieldsData;
  HARDNESS_HALF_RADIUS: FieldsData;
  HARDNESS_THIRD_RADIUS: FieldsData;
  HARDNESS_NUCLEUS: FieldsData;
  HARDNESS_SUPERFICIAL: FieldsData;
  IDEAL_CRITICAL_DIAMETER: FieldsData;
  LOAD_LIMIT: FieldsData;
  YIELD_LIMIT: FieldsData;
  AREA_SHRINK: FieldsData;
  STRETCH: FieldsData;
  TRACTION: FieldsData;
  SPHEROIDIZING_DEGREE: FieldsData;
  MACRO_ETCH: FieldsData;
  MICROSTRUCTURE: FieldsData;
  MICRO_INCLUSION: FieldsData;
  SEGREGATION_C: FieldsData;
  SEGREGATION_S: FieldsData;
  AUSTENITE_GRAIN_SIZE: FieldsData;
  PARTIAL_DECARBURIZING: FieldsData;
  TOTAL_DECARBURIZING: FieldsData;
  GLOBAL_DECARBURIZING: FieldsData;
  ASTM_DS: FieldsData;
  INTERNAL_FLAWS: FieldsData;
  SUPERFICIAL_FLAWS: FieldsData;
  BLUE_FRACTURE: FieldsData;
  EDGE_CONDITIONING: FieldsData;
  COLOR: FieldsData;
  FREE: FieldsData;
  PART_NUMBER: FieldsData;
  BUNDLE_WEIGHT: FieldsData;
  CASTING: FieldsData;
  IMPACTO_V: FieldsData;
  IMPACTO_V_CELCIUS: FieldsData;
  IMPACTO_U: FieldsData;
  IMPACTO_U_CELCIUS: FieldsData;
  ROUNDNESS: FieldsData;
}
interface ClientFieldsData {
  NEW_CLIENT: FieldsData;
  CLIENT: FieldsData;
  CLIENT_CUSTOM: FieldsData;
  CNPJ: FieldsData;
  WHEN_CLIENT_DATA: FieldsData;
  FLOW: FieldsData;
  CLIENT_CODE: FieldsData;
  CORPORATE_CODE: FieldsData;
}

interface GoalFieldsData {
  CLIENT_PROCESS: FieldsData;
  FINAL_APPLICATION: FieldsData;
  CUSTOMER: FieldsData;
  MARKET: FieldsData;
  SOP: FieldsData;
}

interface ProductFieldsData {
  SPECIFICATION_PRODUCT: FieldsData;
  PRODUCT: FieldsData;
  GERDAU_PROCESS_WIRE_ROD: FieldsData;
  GERDAU_PROCESS_WIRE: FieldsData;
  GERDAU_PROCESS_BAR: FieldsData;
  GERDAU_PROCESS_BLOCK: FieldsData;
  GERDAU_PROCESS_INGOT: FieldsData;
  GERDAU_PROCESS_BILLET: FieldsData;
  SHAPE_WIRE_ROD: FieldsData;
  SHAPE_WIRE: FieldsData;
  SHAPE_BAR: FieldsData;
  SHAPE_BLOCK: FieldsData;
  SHAPE_BILLET: FieldsData;
  SURFACE_FINISH: FieldsData;
  HEAT_TREATMENT_WIRE: FieldsData;
  HEAT_TREATMENT_BAR: FieldsData;
  HEAT_TREATMENT_WIRE_ROD: FieldsData;
  HEAT_TREATMENT_BLOCK: FieldsData;
  HEAT_TREATMENT_INGOT: FieldsData;
  HEAT_TREATMENT_BILLET: FieldsData;
  DIAMETER: FieldsData;
  WIDTH: FieldsData;
  THICKNESS: FieldsData;
  SIDE: FieldsData;
  INGOT_WEIGHT: FieldsData;
  CORNER_RADIUS: FieldsData;
  LENGTH: FieldsData;
  LENGTH_TOLERANCE: FieldsData;
  ACCEPTED_SHORT: FieldsData;
  SHORTER_PERCENTAGE: FieldsData;
  SHORTER_SIZE: FieldsData;
  SHORTER_PACKAGE: FieldsData;
  BENDING: FieldsData;
  DIAMETER_TOLERANCE: FieldsData;
  AMOUNT: FieldsData;
  ADDITIONAL_DIAMETER: FieldsData;
  ADDITIONAL_SIDE: FieldsData;
  PRODUCT_REVISION_NORM: FieldsData;
  SPECIFICATION_KEY: FieldsData;
}
interface SteelFieldsData {
  STEEL_NAME: FieldsData;
  STEEL_SEARCH?: FieldsData;
  NORM_INTERNATIONAL: FieldsData;
  CHEMICAL_COMPOSITION: FieldsData;
  SPECIFIC_SPECIFICATION: FieldsData;
  CHECK_FILE: FieldsData;
  SPECIFIC_SPECIFICATION_NAME: FieldsData;
  SPECIFIC_SPECIFICATION_REVISION_NORM: FieldsData;
  SPECIFICATION_REVISION: FieldsData;
  SPECIFICATION_DATE: FieldsData;
  STANDARD: FieldsData;
  SPECIFIC_NORM: FieldsData;
}
interface DocumentFieldsData {
  SPECIFIC_SPECIFICATION_CLIENT_MANUAL: FieldsData;
  SPECIFICATION_REVISION_CLIENT_MANUAL: FieldsData;
  SPECIFICATION_DATE_CLIENT_MANUAL: FieldsData;
}
interface RevisionFieldsData {
  SPECIFIC_SPECIFICATION_NAME_REVISION_NORM: FieldsData;
  SPECIFIC_SPECIFICATION_REVISION_NORM: FieldsData;
  SPECIFICATION_REVISION_REVISION_NORM: FieldsData;
  SPECIFICATION_DATE_REVISION_NORM: FieldsData;
  STANDARD_REVISION_NORM: FieldsData;
  CHECK_FILE_REVISION_NORM: FieldsData;
}

export interface FormResultData {
  ADDITIONAL?: {
    key: string;
    questions: {
      fields: AdditionalFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
  CLIENT?: {
    key: string;
    questions: {
      fields: ClientFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
  GOAL?: {
    key: string;
    questions: {
      fields: GoalFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
  PRODUCT?: {
    key: string;
    questions: {
      fields: ProductFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
  STEEL?: {
    key: string;
    questions: {
      fields: SteelFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
  DOCUMENT?: {
    key: string;
    questions: {
      fields: DocumentFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
  REVISION?: {
    key: string;
    questions: {
      fields: RevisionFieldsData;
      key: string;
      label: string;
      question: string;
    };
  };
}

function convertValue(value, type) {
  if (value) {
    if (type === 'range' && value !== undefined) {
      const val = value.split(' a ');
      return { min: parseFloat(val[0].replace(',', '.')), max: parseFloat(val[1].replace(',', '.')) };
    } else {
      return { key: value.key, label: value.label, value: value?.value };
    }
  } else {
    return null;
  }
}

function convertToNumber(value) {
  if (value) {
    if (value !== undefined && typeof value === 'string') {
      return parseFloat(value.replace(',', '.'));
    } else {
      return value;
    }
  } else {
    return null;
  }
}

function gerarUUID(): string {
  const randomValues = crypto.getRandomValues(new Uint8Array(16));

  randomValues[6] = (randomValues[6] & 0x0f) | 0x40; // Versão 4 (UUID)

  randomValues[8] = (randomValues[8] & 0x3f) | 0x80; // Variante

  return [...randomValues]
    .map((b, i) => {
      const hex = b.toString(16).padStart(2, '0');
      if (i === 4 || i === 6 || i === 8 || i === 10) {
        return `-${hex}`;
      }
      return hex;
    })
    .join('');
}

export const mapPayloadAnalysisOld = (data: FormResultData) => {
  const productKey = data?.PRODUCT?.questions?.fields?.PRODUCT?.value?.key;

  const aditionalAttributes = [];
  const secondaryDimentions = [];
  const othersAttribute = [];

  data?.ADDITIONAL &&
    Object.entries(data?.ADDITIONAL?.questions?.fields).forEach((field: [string, FieldsData]) => {
      const [, object] = field;
      object?.otherAttribute &&
        othersAttribute.push({
          LABEL: object.label,
          VALUE: object.value,
          KEY: gerarUUID(),
          plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
        });
    });

  data?.PRODUCT &&
    Object.entries(data?.PRODUCT?.questions?.fields).forEach((field: [string, FieldsData]) => {
      const [, object] = field;

      if (!object.key) {
        return;
      }

      if (object.key.includes('SIDE_') && object?.value) {
        aditionalAttributes.push({
          LABEL: object.label,
          VALUE: convertToNumber(object.value),
          value: convertToNumber(object.value),
          unit: object.unit.symbol,
          plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
        });
        const [_, num] = object.key.split('_');
        if (num) {
          secondaryDimentions.push({
            dimensionId: object.key.replace('SIDE', 'DIAMETER'),
            diametro: convertToNumber(object.value),
            comprimentoMin: data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value
              ? convertValue(data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value, 'range').min
              : null,
            comprimentoMax: data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value
              ? convertValue(data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value, 'range').max
              : null,
            consumo: data?.PRODUCT?.questions?.fields[`AMOUNT_${num}`]?.value
              ? convertToNumber(data?.PRODUCT?.questions?.fields[`AMOUNT_${num}`]?.value)
              : null,
          });
        }
      } else if (object.key.includes('AMOUNT_') && object?.value) {
        aditionalAttributes.push({
          LABEL: object.label,
          VALUE: convertToNumber(object.value),
          value: convertToNumber(object.value),
          unit: object.unit.symbol,
          plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
        });
      } else if (object.key.includes('WIDTH_') && object?.value) {
        aditionalAttributes.push({
          LABEL: object.label,
          VALUE: convertToNumber(object.value),
          value: convertToNumber(object.value),
          unit: object.unit.symbol,
          plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
        });

        const [_, num] = object.key.split('_');

        if (num) {
          aditionalAttributes.push({
            LABEL: data?.PRODUCT?.questions?.fields[`THICKNESS_${num}`]?.label,
            VALUE: convertToNumber(data?.PRODUCT?.questions?.fields[`THICKNESS_${num}`]?.value),
            value: convertToNumber(data?.PRODUCT?.questions?.fields[`THICKNESS_${num}`]?.value),
            unit: data?.PRODUCT?.questions?.fields[`THICKNESS_${num}`]?.unit.symbol,
            plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
          });

          secondaryDimentions.push({
            dimensionId: object.key,
            largura: convertToNumber(object.value),
            espessura: convertToNumber(data?.PRODUCT?.questions?.fields[`THICKNESS_${num}`]?.value),
            comprimentoMin: data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value
              ? convertValue(data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value, 'range').min
              : null,
            comprimentoMax: data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value
              ? convertValue(data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value, 'range').max
              : null,
            consumo: convertToNumber(data?.PRODUCT?.questions?.fields[`AMOUNT_${num}`]?.value),
          });
        }
      } else if (object.key.includes('DIAMETER_') && !object.key.includes('TOLERANCE') && object?.value) {
        aditionalAttributes.push({
          LABEL: object.label,
          VALUE: convertToNumber(object.value),
          value: convertToNumber(object.value),
          unit: object.unit.symbol,
          plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
        });

        const [_, num] = object.key.split('_');
        if (num) {
          secondaryDimentions.push({
            dimensionId: object.key,
            diametro: convertToNumber(object.value),
            comprimentoMin: data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value
              ? convertValue(data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value, 'range').min
              : null,
            comprimentoMax: data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value
              ? convertValue(data?.PRODUCT?.questions?.fields[`LENGTH_${num}`]?.value, 'range').max
              : null,
            consumo: convertToNumber(data?.PRODUCT?.questions?.fields[`AMOUNT_${num}`]?.value),
          });
        }
      } else if (object.key.includes('LENGTH_') && !object.key.includes('TOLERANCE') && object?.value) {
        aditionalAttributes.push({
          LABEL: object.label,
          VALUE: {
            min: object.value ? convertValue(object.value, 'range').min : null,
            max: object.value ? convertValue(object.value, 'range').max : null,
          },
          value: {
            min: object.value ? convertValue(object.value, 'range').min : null,
            max: object.value ? convertValue(object.value, 'range').max : null,
          },
          unit: object?.unit?.symbol,
          plants: ['pindamonhangaba', 'charqueadas', 'mogiDasCruzes'],
        });
      }
    });

  let flowValue;
  let specificationType = 'SPECIFIC';
  if (data?.CLIENT?.questions?.fields?.FLOW?.value?.value === 'REVISION_NORM') {
    flowValue = data?.REVISION?.questions?.fields?.STANDARD_REVISION_NORM?.value?.value;
    if (data?.REVISION?.questions?.fields?.STANDARD_REVISION_NORM?.value?.value) {
      specificationType = data?.REVISION?.questions?.fields?.STANDARD_REVISION_NORM?.value?.value.split('_')[0];
    }
  } else if (data?.CLIENT?.questions?.fields?.FLOW?.value?.value === 'CLIENT_MANUAL') {
    flowValue = 'CLIENT_MANUAL';
  } else {
    flowValue = data?.CLIENT?.questions?.fields?.FLOW?.value?.value;
  }

  const SPECIFICATION = {};
  const query = {
    //environment: 'development', //development, production
    version: 2, // sempre 2
    //preferredLanguage: 'por', //ing, esp, por
    edit: false,
    flow: flowValue,
    FLOW: flowValue,
    forceRedirect: true, // sempre true
  };

  othersAttribute.length > 0 && aditionalAttributes.length > 0
    ? Object.assign(query, {
        OTHERS_ATTRIBUTES: [...othersAttribute, ...aditionalAttributes],
      })
    : Object.assign(query, {
        OTHERS_ATTRIBUTES: othersAttribute,
      });

  const normName =
    data?.REVISION?.questions?.fields?.SPECIFIC_SPECIFICATION_REVISION_NORM?.value ||
    data?.STEEL?.questions?.fields?.SPECIFIC_SPECIFICATION_REVISION_NORM?.value ||
    data?.REVISION?.questions?.fields?.SPECIFIC_SPECIFICATION_NAME_REVISION_NORM?.value;

  flowValue === 'GENERAL_SPECIFICATION' &&
    Object.assign(query, {
      hasSpecificationFlag: false,
      selectedNewNorm: data?.REVISION?.questions?.fields?.SPECIFIC_SPECIFICATION_NAME_REVISION_NORM?.value
        ? true
        : false,
      GENERAL_SPECIFICATION: normName,
      NORMA_GERAL: normName,
    });

  flowValue === 'SPECIFIC_SPECIFICATION' &&
    Object.assign(query, {
      hasSpecificationFlag: true,
      selectedNewNorm: data?.REVISION?.questions?.fields?.SPECIFIC_SPECIFICATION_NAME_REVISION_NORM?.value
        ? true
        : false,
      SPECIFIC_SPECIFICATION: normName,
      NORMA_ESPECIFICA: normName,
    });

  flowValue === 'NEW_PRODUCT' &&
    Object.assign(query, {
      hasSpecificationFlag:
        data?.STEEL?.questions?.fields?.NORM_INTERNATIONAL?.value?.value === 'GENERAL_SPECIFICATION' ? false : true,
      selectedNewNorm: data?.STEEL?.questions?.fields?.SPECIFIC_SPECIFICATION_NAME?.value ? true : false,
      SPECIFIC_SPECIFICATION: normName,
      NORMA_ESPECIFICA: normName,
    });

  flowValue === 'CLIENT_MANUAL' &&
    Object.assign(query, {
      hasSpecificationFlag: true,
      selectedNewNorm: false,
      SPECIFIC_SPECIFICATION: data?.DOCUMENT?.questions?.fields?.SPECIFIC_SPECIFICATION_CLIENT_MANUAL?.value,
      NORMA_ESPECIFICA: data?.DOCUMENT?.questions?.fields?.SPECIFIC_SPECIFICATION_CLIENT_MANUAL?.value,
      SPECIFICATION_REVISION: data?.DOCUMENT?.questions?.fields?.SPECIFICATION_REVISION_CLIENT_MANUAL?.value,
      REVISAO_NORMA: data?.DOCUMENT?.questions?.fields?.SPECIFICATION_REVISION_CLIENT_MANUAL?.value,
      SPECIFICATION_DATE: format(
        new Date(data?.DOCUMENT?.questions?.fields?.SPECIFICATION_DATE_CLIENT_MANUAL?.value),
        'yyyy-MM-dd',
      ),
      DATA_NORMA: format(
        new Date(data?.DOCUMENT?.questions?.fields?.SPECIFICATION_DATE_CLIENT_MANUAL?.value),
        'yyyy-MM-dd',
      ),
      SPECIFICATION: {
        NAME: data?.DOCUMENT?.questions?.fields?.SPECIFIC_SPECIFICATION_CLIENT_MANUAL?.value,
        DATE: format(
          new Date(data?.DOCUMENT?.questions?.fields?.SPECIFICATION_DATE_CLIENT_MANUAL?.value),
          'yyyy-MM-dd',
        ),
        REVISION: data?.DOCUMENT?.questions?.fields?.SPECIFICATION_REVISION_CLIENT_MANUAL?.value,
        TYPE: specificationType,
      },
    });

  data?.CLIENT?.questions?.fields?.FLOW?.value?.value === 'REVISION_NORM' &&
    Object.assign(query, {
      hasSpecificationFlag: true,
      selectedNewNorm: false,
      SPECIFIC_SPECIFICATION: normName,
      NORMA_ESPECIFICA: normName,
      SPECIFICATION_REVISION: data?.REVISION?.questions?.fields?.SPECIFICATION_REVISION_REVISION_NORM?.value,
      REVISAO_NORMA: data?.REVISION?.questions?.fields?.SPECIFICATION_REVISION_REVISION_NORM?.value,
      SPECIFICATION_DATE: format(
        new Date(data?.REVISION?.questions?.fields?.SPECIFICATION_DATE_REVISION_NORM?.value),
        'yyyy-MM-dd',
      ),
      DATA_NORMA: format(
        new Date(data?.REVISION?.questions?.fields?.SPECIFICATION_DATE_REVISION_NORM?.value),
        'yyyy-MM-dd',
      ),
      SPECIFICATION: {
        NAME: normName,
        DATE: format(
          new Date(data?.REVISION?.questions?.fields?.SPECIFICATION_DATE_REVISION_NORM?.value),
          'yyyy-MM-dd',
        ),
        REVISION: data?.REVISION?.questions?.fields?.SPECIFICATION_REVISION_REVISION_NORM?.value,
        TYPE: specificationType,
      },
    });

  data?.PRODUCT?.questions?.fields?.PRODUCT_REVISION_NORM?.value &&
    Object.assign(query, {
      OPTIONS: data?.PRODUCT?.questions?.fields?.PRODUCT_REVISION_NORM?.value,
    });

  data?.CLIENT?.questions?.fields?.CLIENT_CODE?.value &&
    Object.assign(query, {
      CLIENT_CODE: data?.CLIENT?.questions?.fields?.CLIENT_CODE?.value,
      CODIGO_CLIENTE: data?.CLIENT?.questions?.fields?.CLIENT_CODE?.value,
    });
  data?.CLIENT?.questions?.fields?.CORPORATE_CODE?.value &&
    Object.assign(query, {
      CORPORATE_CODE: data?.CLIENT?.questions?.fields?.CORPORATE_CODE?.value,
    });
  data?.CLIENT?.questions?.fields?.CNPJ?.value &&
    Object.assign(query, {
      CNPJ: convertCNPJ(data?.CLIENT?.questions?.fields?.CNPJ?.value),
    });

  data?.CLIENT?.questions?.fields?.CLIENT?.value &&
    Object.assign(query, {
      CLIENTE: data?.CLIENT?.questions?.fields?.CLIENT?.value,
      CLIENT: data?.CLIENT?.questions?.fields?.CLIENT?.value,
      HQ_NAME: data?.CLIENT?.questions?.fields?.CLIENT?.value,
    });

  data?.CLIENT?.questions?.fields?.CLIENT_CUSTOM?.value &&
    Object.assign(query, {
      CLIENTE: data?.CLIENT?.questions?.fields?.CLIENT_CUSTOM?.value,
      CLIENT: data?.CLIENT?.questions?.fields?.CLIENT_CUSTOM?.value,
    });

  data?.CLIENT?.questions?.fields?.WHEN_CLIENT_DATA?.value &&
    Object.assign(query, {
      WHEN_CLIENT_DATA: format(new Date(data?.CLIENT?.questions?.fields?.WHEN_CLIENT_DATA?.value), 'yyyy-MM-dd'),
    });
  data?.STEEL?.questions?.fields?.STEEL_NAME?.value &&
    Object.assign(query, {
      STEEL_NAME: data?.STEEL?.questions?.fields?.STEEL_NAME?.value?.value
        ? data.STEEL.questions.fields.STEEL_NAME.value?.value
        : data?.STEEL.questions?.fields?.STEEL_NAME?.value,
    });

  data?.PRODUCT?.questions?.fields?.SPECIFICATION_KEY?.value &&
    Object.assign(query, {
      SPECIFICATION_KEY: data?.PRODUCT?.questions?.fields?.SPECIFICATION_KEY?.value,
    });

  data?.STEEL?.questions?.fields?.NORM_INTERNATIONAL?.value &&
    Object.assign(query, {
      flagInternationalStandard:
        data?.STEEL?.questions?.fields?.NORM_INTERNATIONAL?.value?.value === 'GENERAL_SPECIFICATION' ? true : false,
    });

  data?.STEEL?.questions?.fields?.SPECIFICATION_REVISION?.value &&
    Object.assign(query, {
      SPECIFICATION_REVISION: data?.STEEL?.questions?.fields?.SPECIFICATION_REVISION?.value,
      REVISAO_NORMA: data?.STEEL?.questions?.fields?.SPECIFICATION_REVISION?.value,
      SPECIFICATION_DATE: format(new Date(data?.STEEL?.questions?.fields?.SPECIFICATION_DATE?.value), 'yyyy-MM-dd'),
      DATA_NORMA: format(new Date(data?.STEEL?.questions?.fields?.SPECIFICATION_DATE?.value), 'yyyy-MM-dd'),
    });

  data?.STEEL?.questions?.fields?.SPECIFIC_SPECIFICATION_REVISION_NORM?.value &&
    Object.assign(SPECIFICATION, {
      NAME: data?.STEEL?.questions?.fields?.SPECIFIC_SPECIFICATION_REVISION_NORM?.value,
      TYPE: specificationType,
    });
  data?.STEEL?.questions?.fields?.SPECIFICATION_REVISION?.value &&
    Object.assign(SPECIFICATION, {
      DATE: format(new Date(data?.STEEL?.questions?.fields?.SPECIFICATION_DATE?.value), 'yyyy-MM-dd'),
      REVISION: data?.STEEL?.questions?.fields?.SPECIFICATION_REVISION?.value,
    });
  data?.STEEL?.questions?.fields?.CHEMICAL_COMPOSITION?.value &&
    Object.assign(query, {
      ELEMENTOS: data?.STEEL?.questions?.fields?.CHEMICAL_COMPOSITION?.value,
      hasChemicalComposition: data?.STEEL?.questions?.fields?.CHEMICAL_COMPOSITION?.value ? true : false,
    });
  JSON.stringify(SPECIFICATION) !== '{}' &&
    Object.assign(query, {
      SPECIFICATION,
    });
  data?.PRODUCT?.questions?.fields[`SHAPE_${productKey}`]?.value?.value &&
    Object.assign(query, {
      FORMA: data?.PRODUCT?.questions?.fields[`SHAPE_${productKey}`]?.value?.value,
      SHAPE: data?.PRODUCT?.questions?.fields[`SHAPE_${productKey}`]?.value?.value,
    });
  data?.PRODUCT?.questions?.fields?.PRODUCT?.value &&
    Object.assign(query, {
      PRODUTO: [data?.PRODUCT?.questions?.fields?.PRODUCT?.value?.value],
      PRODUCT: [data?.PRODUCT?.questions?.fields?.PRODUCT?.value?.value],
    });
  data?.PRODUCT?.questions?.fields?.PRODUCT_REVISION_NORM?.value &&
    Object.assign(query, {
      PRODUTO: data?.PRODUCT?.questions?.fields?.PRODUCT_REVISION_NORM?.value,
      PRODUCT: data?.PRODUCT?.questions?.fields?.PRODUCT_REVISION_NORM?.value,
    });
  data?.PRODUCT?.questions?.fields?.SURFACE_FINISH?.value &&
    Object.assign(query, {
      ACABAMENTO: data?.PRODUCT?.questions?.fields?.SURFACE_FINISH?.value?.value,
      SURFACE_FINISH: data?.PRODUCT?.questions?.fields?.SURFACE_FINISH?.value?.value,
    });
  data?.PRODUCT?.questions?.fields[`HEAT_TREATMENT_${productKey}`]?.value?.value &&
    Object.assign(query, {
      TRATAMENTO_TERMICO: [data?.PRODUCT?.questions?.fields[`HEAT_TREATMENT_${productKey}`]?.value?.value],
      HEAT_TREATMENT: [data?.PRODUCT?.questions?.fields[`HEAT_TREATMENT_${productKey}`]?.value?.value],
    });
  data?.PRODUCT?.questions?.fields?.CORNER_RADIUS?.value &&
    Object.assign(query, {
      CORNER_RADIUS: data?.PRODUCT?.questions?.fields?.CORNER_RADIUS?.value?.value,
      RAIO_DE_CANTO: data?.PRODUCT?.questions?.fields?.CORNER_RADIUS?.value?.value,
    });

  data?.ADDITIONAL?.questions?.fields?.NUMERIC_CORNER_RADIUS?.value &&
    Object.assign(query, {
      RAIO_DE_CANTO_MMMINNTOMIN:
        data?.ADDITIONAL?.questions?.fields?.NUMERIC_CORNER_RADIUS?.value &&
        convertValue(data?.ADDITIONAL?.questions?.fields?.NUMERIC_CORNER_RADIUS?.value, 'range').min,
      RAIO_DE_CANTO_MMMAX:
        data?.ADDITIONAL?.questions?.fields?.NUMERIC_CORNER_RADIUS?.value &&
        convertValue(data?.ADDITIONAL?.questions?.fields?.NUMERIC_CORNER_RADIUS?.value, 'range').max,
      MEDIDA_RAIO_DE_CANTO: data?.ADDITIONAL?.questions?.fields?.NUMERIC_CORNER_RADIUS?.unit?.symbol,
    });

  data?.PRODUCT?.questions?.fields[`GERDAU_PROCESS_${productKey}`]?.value?.value &&
    Object.assign(query, {
      PROCESSO_GERDAU: data?.PRODUCT?.questions?.fields[`GERDAU_PROCESS_${productKey}`]?.value?.value,
      GERDAU_PROCESS: data?.PRODUCT?.questions?.fields[`GERDAU_PROCESS_${productKey}`]?.value?.value,
    });
  data?.PRODUCT?.questions?.fields?.DIAMETER?.value &&
    Object.assign(query, {
      BITOLA: convertToNumber(data?.PRODUCT?.questions?.fields?.DIAMETER?.value),
      MEDIDA_BITOLA: data?.PRODUCT?.questions?.fields?.DIAMETER?.unit?.symbol,
      DIAMETER: convertToNumber(data?.PRODUCT?.questions?.fields?.DIAMETER?.value),
    });
  data?.PRODUCT?.questions?.fields?.LENGTH?.value &&
    Object.assign(query, {
      COMPRIMENTOMIN:
        data?.PRODUCT?.questions?.fields?.LENGTH?.value &&
        convertValue(data?.PRODUCT?.questions?.fields?.LENGTH?.value, 'range').min,
      COMPRIMENTOMAX:
        data?.PRODUCT?.questions?.fields?.LENGTH?.value &&
        convertValue(data?.PRODUCT?.questions?.fields?.LENGTH?.value, 'range').max,
      COMPRIMENTO_MEDIDA_MIN: data?.PRODUCT?.questions?.fields?.LENGTH?.unit?.symbol,
      COMPRIMENTO_MEDIDA_MAX: data?.PRODUCT?.questions?.fields?.LENGTH?.unit?.symbol,

      COMPRIMENTO: data?.PRODUCT?.questions?.fields?.LENGTH?.value,
    });
  aditionalAttributes.length > 0 && othersAttribute.length > 0
    ? Object.assign(query, {
        OTHERS_ATTRIBUTES: [...aditionalAttributes, ...othersAttribute],
      })
    : Object.assign(query, {
        OTHERS_ATTRIBUTES: aditionalAttributes,
      });
  secondaryDimentions.length > 0 &&
    Object.assign(query, {
      SECONDARY_DIMENSIONS: secondaryDimentions,
    });
  data?.PRODUCT?.questions?.fields?.AMOUNT?.value &&
    Object.assign(query, {
      CONSUMO: convertToNumber(data?.PRODUCT?.questions?.fields?.AMOUNT?.value),
      MEDIDA_CONSUMO: data?.PRODUCT?.questions?.fields?.AMOUNT?.unit?.symbol,
      AMOUNT: convertToNumber(data?.PRODUCT?.questions?.fields?.AMOUNT?.value),
    });
  data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value &&
    Object.assign(query, {
      PROCESSO_CLIENTE: data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value?.value
        ? data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value?.value
        : data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value,
      CLIENT_PROCESS: data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value?.value
        ? data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value?.value
        : data?.GOAL?.questions?.fields?.CLIENT_PROCESS?.value,
    });
  data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value &&
    Object.assign(query, {
      APLICACAO_FINAL: data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value?.value
        ? data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value?.value
        : data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value,
      FINAL_APPLICATION: data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value?.value
        ? data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value?.value
        : data?.GOAL?.questions?.fields?.FINAL_APPLICATION?.value,
    });
  data?.GOAL?.questions?.fields?.CUSTOMER?.value &&
    Object.assign(query, { CUSTOMER: data?.GOAL?.questions?.fields?.CUSTOMER?.value?.join() });
  data?.GOAL?.questions?.fields?.MARKET?.value &&
    Object.assign(query, {
      MERCADO: data?.GOAL?.questions?.fields?.MARKET?.value?.value,
      MARKET: data?.GOAL?.questions?.fields?.MARKET?.value?.value,
    });
  data?.GOAL?.questions?.fields?.SOP?.value &&
    Object.assign(query, {
      SOP: data?.GOAL?.questions?.fields?.SOP?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.BENDING?.value &&
    Object.assign(query, {
      BendingOrigin: 'user', //sempre
      EMPENAMENTO: data?.ADDITIONAL?.questions?.fields?.BENDING?.value,
      MEDIDA_EMPENAMENTO: data?.ADDITIONAL?.questions?.fields?.BENDING?.unit?.symbol,
      BENDING: data?.ADDITIONAL?.questions?.fields?.BENDING?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.CROOKED?.value &&
    Object.assign(query, {
      FORA_DE_ESQUADRO: data?.ADDITIONAL?.questions?.fields?.CROOKED?.value,
      CROOKED: data?.ADDITIONAL?.questions?.fields?.CROOKED?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.LENGTH_MULTIPLES?.value &&
    Object.assign(query, {
      MULTIPLO_COMPRIMENTO: parseFloat(data?.ADDITIONAL?.questions?.fields?.LENGTH_MULTIPLES?.value),
      MULTIPLO_COMPRIMENTO_MEDIDA: data?.ADDITIONAL?.questions?.fields?.LENGTH_MULTIPLES?.unit?.symbol,
      MULTIPLO_COMPRIMENTOMIN: parseFloat(data?.ADDITIONAL?.questions?.fields?.LENGTH_MULTIPLES?.min),
      MULTIPLO_COMPRIMENTOMAX: parseFloat(data?.ADDITIONAL?.questions?.fields?.LENGTH_MULTIPLES?.max),
    });
  data?.ADDITIONAL?.questions?.fields?.ROUNDNESS?.value &&
    Object.assign(query, {
      OVALIZACAO: data?.ADDITIONAL?.questions?.fields?.ROUNDNESS?.value,
      MEDIDA_OVALIZACAO: data?.ADDITIONAL?.questions?.fields?.ROUNDNESS?.unit?.symbol,
      ROUNDNESS: data?.ADDITIONAL?.questions?.fields?.ROUNDNESS?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.REDUCTION_RATE?.value &&
    Object.assign(query, {
      TAXA_REDUCAO: data?.ADDITIONAL?.questions?.fields?.REDUCTION_RATE?.value,
      REDUCTION_RATE: data?.ADDITIONAL?.questions?.fields?.REDUCTION_RATE?.value,
    });
  data?.PRODUCT?.questions?.fields?.DIAMETER_TOLERANCE?.value &&
    Object.assign(query, {
      ToleranceOrigin: 'user',
      MEDIDA_TOL_BITOLA: data?.PRODUCT?.questions?.fields?.DIAMETER_TOLERANCE?.unit?.symbol,
      TOL_BITOLAMIN: convertValue(data?.PRODUCT?.questions?.fields?.DIAMETER_TOLERANCE?.value, 'range').min,
      TOL_BITOLAMAX: convertValue(data?.PRODUCT?.questions?.fields?.DIAMETER_TOLERANCE?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.HARDNESS_HALF_RADIUS?.value &&
    Object.assign(query, {
      MEDIDA_DUREZAMEIORAIO: data?.ADDITIONAL?.questions?.fields?.HARDNESS_HALF_RADIUS?.unit?.symbol,
      DUREZAMEIORAIOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_HALF_RADIUS?.value, 'range').min,
      DUREZAMEIORAIOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_HALF_RADIUS?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.HARDNESS_NUCLEUS?.value &&
    Object.assign(query, {
      MEDIDA_DUREZANUCLEO: data?.ADDITIONAL?.questions?.fields?.HARDNESS_NUCLEUS?.unit?.symbol,
      DUREZANUCLEOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_NUCLEUS?.value, 'range').min,
      DUREZANUCLEOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_NUCLEUS?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.HARDNESS_SUPERFICIAL?.value &&
    Object.assign(query, {
      MEDIDA_DUREZASUPERFICIAL: data?.ADDITIONAL?.questions?.fields?.HARDNESS_SUPERFICIAL?.unit?.symbol,
      DUREZASUPERFICIALMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_SUPERFICIAL?.value, 'range').min,
      DUREZASUPERFICIALMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_SUPERFICIAL?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.HARDNESS_THIRD_RADIUS?.value &&
    Object.assign(query, {
      MEDIDA_DUREZATERCORAIO: data?.ADDITIONAL?.questions?.fields?.HARDNESS_THIRD_RADIUS?.unit?.symbol,
      DUREZATERCORAIOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_THIRD_RADIUS?.value, 'range').min,
      DUREZATERCORAIOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.HARDNESS_THIRD_RADIUS?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.IMPACTO_V?.value &&
    Object.assign(query, {
      CORPO_DE_PROVA: 'Corpo de prova V',
      IMPACTO_V: data?.ADDITIONAL?.questions?.fields?.IMPACTO_V?.value,
      MEDIDA_IMPACTO_V: data?.ADDITIONAL?.questions?.fields?.IMPACTO_V?.unit?.symbol,
      IMPACTO_V_CELCIUS: data?.ADDITIONAL?.questions?.fields?.IMPACTO_V_CELCIUS?.value,
      MEDIDA_IMPACTO_V_CELCIUS: data?.ADDITIONAL?.questions?.fields?.IMPACTO_V_CELCIUS?.unit?.symbol,
    });
  data?.ADDITIONAL?.questions?.fields?.IMPACTO_U?.value &&
    Object.assign(query, {
      CORPO_DE_PROVA: 'Corpo de prova U',
      IMPACTO_V: data?.ADDITIONAL?.questions?.fields?.IMPACTO_U?.value,
      MEDIDA_IMPACTO_V: data?.ADDITIONAL?.questions?.fields?.IMPACTO_U?.unit?.symbol,
      IMPACTO_V_CELCIUS: data?.ADDITIONAL?.questions?.fields?.IMPACTO_U_CELCIUS?.value,
      MEDIDA_IMPACTO_V_CELCIUS: data?.ADDITIONAL?.questions?.fields?.IMPACTO_U_CELCIUS?.unit?.symbol,
    });
  data?.ADDITIONAL?.questions?.fields?.STRETCH?.value &&
    Object.assign(query, {
      ALONGAMENTO: data?.ADDITIONAL?.questions?.fields?.STRETCH?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.YIELD_LIMIT?.value &&
    Object.assign(query, {
      MEDIDA_LIMITE_ESCOAMENTO: data?.ADDITIONAL?.questions?.fields?.YIELD_LIMIT?.unit?.symbol,
      LIMITE_ESCOAMENTOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.YIELD_LIMIT?.value, 'range').min,
      LIMITE_ESCOAMENTOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.YIELD_LIMIT?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.LOAD_LIMIT?.value &&
    Object.assign(query, {
      MEDIDA_LIMITE_RESISTENCIA: data?.ADDITIONAL?.questions?.fields?.LOAD_LIMIT?.unit?.symbol,
      LIMITE_RESISTENCIAMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.LOAD_LIMIT?.value, 'range').min,
      LIMITE_RESISTENCIAMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.LOAD_LIMIT?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.STRETCH?.value &&
    Object.assign(query, {
      STRETCH: data?.ADDITIONAL?.questions?.fields?.STRETCH?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.AREA_SHRINK?.value &&
    Object.assign(query, {
      REDUCAO_AREA: data?.ADDITIONAL?.questions?.fields?.AREA_SHRINK?.value,
      AREA_SHRINK: data?.ADDITIONAL?.questions?.fields?.AREA_SHRINK?.value,
      MEDIDA_DI: data?.ADDITIONAL?.questions?.fields?.AREA_SHRINK?.unit?.symbol,
    });
  data?.ADDITIONAL?.questions?.fields?.IDEAL_CRITICAL_DIAMETER?.value &&
    Object.assign(query, {
      DIMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.IDEAL_CRITICAL_DIAMETER?.value, 'range').min,
      DIMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.IDEAL_CRITICAL_DIAMETER?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.GLOBAL_DECARBURIZING?.value &&
    Object.assign(query, {
      TAMANHODESCARBONETACAO: data?.ADDITIONAL?.questions?.fields?.GLOBAL_DECARBURIZING?.value,
      DESCARBONETACAO_GLOBAL: data?.ADDITIONAL?.questions?.fields?.GLOBAL_DECARBURIZING?.value,
      GLOBAL_DECARBURIZING: data?.ADDITIONAL?.questions?.fields?.GLOBAL_DECARBURIZING?.unit?.symbol,
      MEDIDA_DESCARBONETACAO_GLOBAL: data?.ADDITIONAL?.questions?.fields?.GLOBAL_DECARBURIZING?.unit?.symbol,
      TIPODESCARBONETACAO: 'GLOBAL',
    });

  data?.ADDITIONAL?.questions?.fields?.TOTAL_DECARBURIZING?.value &&
    Object.assign(query, {
      DESCARBONETACAO_TOTAL: data?.ADDITIONAL?.questions?.fields?.TOTAL_DECARBURIZING?.value,
      TOTAL_DECARBURIZING: data?.ADDITIONAL?.questions?.fields?.TOTAL_DECARBURIZING?.value,
      MEDIDA_DESCARBONETACAO_TOTAL: data?.ADDITIONAL?.questions?.fields?.TOTAL_DECARBURIZING?.unit?.symbol,
    });

  const ARRAYDESCARBONETACAO = [];
  data?.ADDITIONAL?.questions?.fields?.GLOBAL_DECARBURIZING?.value && ARRAYDESCARBONETACAO.push('GLOBAL');
  data?.ADDITIONAL?.questions?.fields?.TOTAL_DECARBURIZING?.value && ARRAYDESCARBONETACAO.push('TOTAL');
  ARRAYDESCARBONETACAO.length &&
    Object.assign(query, {
      ARRAYDESCARBONETACAO,
    });
  data?.ADDITIONAL?.questions?.fields?.SPHEROIDIZING_DEGREE?.value &&
    Object.assign(query, {
      GESFERIODIZACAO_INPUT: data?.ADDITIONAL?.questions?.fields?.SPHEROIDIZING_DEGREE?.value,
      GRAU_ESFERIODIZACAOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.SPHEROIDIZING_DEGREE?.value, 'range')
        .min,
      GRAU_ESFERIODIZACAOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.SPHEROIDIZING_DEGREE?.value, 'range')
        .max,
    });
  data?.ADDITIONAL?.questions?.fields?.MACRO_ETCH?.value &&
    Object.assign(query, {
      MACROATAQUE_S: parseFloat(data?.ADDITIONAL?.questions?.fields?.MACRO_ETCH?.value[0].value),
      MACROATAQUE_R: parseFloat(data?.ADDITIONAL?.questions?.fields?.MACRO_ETCH?.value[1].value),
      MACROATAQUE_C: parseFloat(data?.ADDITIONAL?.questions?.fields?.MACRO_ETCH?.value[2].value),
    });
  data?.ADDITIONAL?.questions?.fields?.MICROSTRUCTURE?.value &&
    Object.assign(query, {
      MICROESTRUTURA: data?.ADDITIONAL?.questions?.fields?.MICROSTRUCTURE?.value?.value,
      MICROSTRUCTURE: data?.ADDITIONAL?.questions?.fields?.MICROSTRUCTURE?.value?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value &&
    Object.assign(query, {
      MICRO_A_FINO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[0].value.FINO,
      MICRO_A_GROSSO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[0].value.GROSSO,
      MICRO_B_FINO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[1].value.FINO,
      MICRO_B_GROSSO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[1].value.GROSSO,
      MICRO_C_FINO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[2].value.FINO,
      MICRO_C_GROSSO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[2].value.GROSSO,
      MICRO_D_FINO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[3].value.FINO,
      MICRO_D_GROSSO: data?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value[3].value.GROSSO,
    });
  data?.ADDITIONAL?.questions?.fields?.SEGREGATION_S?.value &&
    Object.assign(query, {
      MEDIDA_SEGREGACAO_S: data?.ADDITIONAL?.questions?.fields?.SEGREGATION_S?.unit?.symbol,
      SEGREGACAO_SMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.SEGREGATION_S?.value, 'range').min,
      SEGREGACAO_SMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.SEGREGATION_S?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.ASTM_DS?.value &&
    Object.assign(query, {
      ASTM_DS: data?.ADDITIONAL?.questions?.fields?.ASTM_DS?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.AUSTENITE_GRAIN_SIZE?.value &&
    Object.assign(query, {
      TAMANHO_GRAOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.AUSTENITE_GRAIN_SIZE?.value, 'range').min,
      TAMANHO_GRAOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.AUSTENITE_GRAIN_SIZE?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.INTERNAL_FLAWS?.value &&
    Object.assign(query, {
      DEFEITOS_INTERNOS: data?.ADDITIONAL?.questions?.fields?.INTERNAL_FLAWS?.value,
      MEDIDA_DEFEITOS_INTERNOS: data?.ADDITIONAL?.questions?.fields?.INTERNAL_FLAWS?.unit?.symbol,
      DEFEITOS_INTERNOSMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.INTERNAL_FLAWS?.value, 'range').min,
      DEFEITOS_INTERNOSMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.INTERNAL_FLAWS?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.SUPERFICIAL_FLAWS?.value &&
    Object.assign(query, {
      EFEITOS_SUPERFICIAIS: data?.ADDITIONAL?.questions?.fields?.SUPERFICIAL_FLAWS?.value,
      MEDIDA_DEFEITOS_SUPERFICIAIS: data?.ADDITIONAL?.questions?.fields?.SUPERFICIAL_FLAWS?.unit?.symbol,
      SUPERFICIAL_FLAWS: data?.ADDITIONAL?.questions?.fields?.SUPERFICIAL_FLAWS?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.BLUE_FRACTURE?.value &&
    Object.assign(query, {
      FRATURA_AZUL: data?.ADDITIONAL?.questions?.fields?.BLUE_FRACTURE?.value?.value,
      BLUE_FRACTURE: data?.ADDITIONAL?.questions?.fields?.BLUE_FRACTURE?.value?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.EDGE_CONDITIONING?.value &&
    Object.assign(query, {
      CONDICAO_TOPO: data?.ADDITIONAL?.questions?.fields?.EDGE_CONDITIONING?.value,
      EDGE_CONDITIONING: data?.ADDITIONAL?.questions?.fields?.EDGE_CONDITIONING?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.COLOR?.value &&
    Object.assign(query, {
      COLOR: [data?.ADDITIONAL?.questions?.fields?.COLOR?.value],
    });
  data?.ADDITIONAL?.questions?.fields?.FREE?.value &&
    Object.assign(query, {
      ISENCAO_OLEO: data?.ADDITIONAL?.questions?.fields?.FREE?.value.includes('Óleo e graxa'),
      ISENCAO_POEIRA: data?.ADDITIONAL?.questions?.fields?.FREE?.value.includes('Poeira'),
      ISENCAO_OXIDACAO: data?.ADDITIONAL?.questions?.fields?.FREE?.value.includes('Oxidação'),
      OIL_FREE: data?.ADDITIONAL?.questions?.fields?.FREE?.value.includes('Óleo e graxa'),
      DUST_FREE: data?.ADDITIONAL?.questions?.fields?.FREE?.value.includes('Poeira'),
      OXIDATION_FREE: data?.ADDITIONAL?.questions?.fields?.FREE?.value.includes('Oxidação'),
    });
  data?.ADDITIONAL?.questions?.fields?.PART_NUMBER?.value &&
    Object.assign(query, {
      PART_NUMBER: data?.ADDITIONAL?.questions?.fields?.PART_NUMBER?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.BUNDLE_WEIGHT?.value &&
    Object.assign(query, {
      PESO_AMARRADO_MEDIDA_MAX: data?.ADDITIONAL?.questions?.fields?.BUNDLE_WEIGHT?.unit?.symbol,
      PESO_AMARRADO_MEDIDA_MIN: data?.ADDITIONAL?.questions?.fields?.BUNDLE_WEIGHT?.unit?.symbol,
      PESO_AMARRADOMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.BUNDLE_WEIGHT?.value, 'range').min,
      PESO_AMARRADOMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.BUNDLE_WEIGHT?.value, 'range').max,
    });
  data?.ADDITIONAL?.questions?.fields?.CASTING?.value &&
    Object.assign(query, {
      CASTING: data?.ADDITIONAL?.questions?.fields?.CASTING?.value?.value,
    });
  data?.ADDITIONAL?.questions?.fields?.TORSION?.value &&
    Object.assign(query, {
      TORCAO: data?.ADDITIONAL?.questions?.fields?.TORSION?.value, /// SOMENTE QUADRADO E RETANGULAR?
      TORSION: data?.ADDITIONAL?.questions?.fields?.TORSION?.value,
    });
  data?.PRODUCT?.questions?.fields?.SIDE?.value &&
    Object.assign(query, {
      SIDE: convertToNumber(data?.PRODUCT?.questions?.fields?.SIDE?.value),
      LADO: convertToNumber(data?.PRODUCT?.questions?.fields?.SIDE?.value),
      MEDIDA_LADO: data?.PRODUCT?.questions?.fields?.SIDE?.unit?.symbol,
    });
  data?.PRODUCT?.questions?.fields?.THICKNESS?.value &&
    Object.assign(query, {
      THICKNESS: convertToNumber(data?.PRODUCT?.questions?.fields?.THICKNESS?.value),
      ESPESSURA: convertToNumber(data?.PRODUCT?.questions?.fields?.THICKNESS?.value),
      MEDIDA_ESPESSURA: data?.PRODUCT?.questions?.fields?.THICKNESS?.unit?.symbol,
    });
  data?.PRODUCT?.questions?.fields?.WIDTH?.value &&
    Object.assign(query, {
      WIDTH: convertToNumber(data?.PRODUCT?.questions?.fields?.WIDTH?.value),
      LARGURA: convertToNumber(data?.PRODUCT?.questions?.fields?.WIDTH?.value),
      MEDIDA_LARGURA: data?.PRODUCT?.questions?.fields?.WIDTH?.unit?.symbol,
    });
  data?.PRODUCT?.questions?.fields?.ACCEPTED_SHORT?.value &&
    Object.assign(query, {
      ACEITE_CURTOS: data?.PRODUCT?.questions?.fields?.ACCEPTED_SHORT?.value === 'Sim' ? true : false,
      SHORTER_ALLOWED: data?.PRODUCT?.questions?.fields?.ACCEPTED_SHORT?.value === 'Sim' ? true : false,
    });

  data?.PRODUCT?.questions?.fields?.LENGTH_TOLERANCE?.value &&
    Object.assign(query, {
      TOL_COMPRIMENTOMIN: convertValue(data?.PRODUCT?.questions?.fields?.LENGTH_TOLERANCE?.value, 'range').min,
      TOL_COMPRIMENTOMAX: convertValue(data?.PRODUCT?.questions?.fields?.LENGTH_TOLERANCE?.value, 'range').max,
      TOL_COMPRIMENTO_MEDIDA_MIN: data?.PRODUCT?.questions?.fields?.LENGTH_TOLERANCE?.unit?.symbol,
      TOL_COMPRIMENTO_MEDIDA_MAX: data?.PRODUCT?.questions?.fields?.LENGTH_TOLERANCE?.unit?.symbol,
    });

  data?.ADDITIONAL?.questions?.fields?.HARDENABILITY?.value &&
    Object.assign(query, {
      JOMINY: data?.ADDITIONAL?.questions?.fields?.HARDENABILITY?.value,
      pointer: data?.ADDITIONAL?.questions?.fields?.HARDENABILITY?.value && ['JOMINY'],
    });

  data?.ADDITIONAL?.questions?.fields?.DIAGONAL_DIFFERENCE?.value &&
    Object.assign(query, {
      MEDIDA_DIFERENCA_DIAGONAL: data?.ADDITIONAL?.questions?.fields?.DIAGONAL_DIFFERENCE?.unit?.symbol,
      DIFERENCA_DIAGONALMIN: convertValue(data?.ADDITIONAL?.questions?.fields?.DIAGONAL_DIFFERENCE?.value, 'range').min,
      DIFERENCA_DIAGONALMAX: convertValue(data?.ADDITIONAL?.questions?.fields?.DIAGONAL_DIFFERENCE?.value, 'range').max,
    });

  data?.PRODUCT?.questions?.fields?.SHORTER_PERCENTAGE?.value &&
    Object.assign(query, {
      PORC_CURTOS: parseFloat(data?.PRODUCT?.questions?.fields?.SHORTER_PERCENTAGE?.value),
      SHORTER_PERCENTAGE: parseFloat(data?.PRODUCT?.questions?.fields?.SHORTER_PERCENTAGE?.value),
    });
  data?.PRODUCT?.questions?.fields?.SHORTER_SIZE?.value &&
    Object.assign(query, {
      TAMANHO_CURTOSMIN: convertValue(data?.PRODUCT?.questions?.fields?.SHORTER_SIZE?.value, 'range').min,
      TAMANHO_CURTOS_MEDIDA_MIN: data?.PRODUCT?.questions?.fields?.SHORTER_SIZE?.unit?.symbol,
      TAMANHO_CURTOS_MEDIDA_MAX: data?.PRODUCT?.questions?.fields?.SHORTER_SIZE?.unit?.symbol,
      TAMANHO_CURTOSMAX: convertValue(data?.PRODUCT?.questions?.fields?.SHORTER_SIZE?.value, 'range').max,
    });
  data?.PRODUCT?.questions?.fields?.SHORTER_PACKAGE?.value &&
    Object.assign(query, {
      EMB_CURTOS: data?.PRODUCT?.questions?.fields?.SHORTER_PACKAGE?.value,
      SHORTER_PACKAGE: data?.PRODUCT?.questions?.fields?.SHORTER_PACKAGE?.value,
    });

  return query;
};
